import * as amplitude from '@amplitude/analytics-browser';

export const trackBrandView = (link: { url?: string; cached_url?: string; redirectTo?: string }, utmParams: any) => {
  const path = link.url || link.cached_url || link.redirectTo || '';
  const redirectToParts = path.split('?');
  const pathSegments = redirectToParts[0].split('/');
  const categorySegment = pathSegments[2] || '';
  const gender = categorySegment.split('-')[0];

  let brand;
  if (redirectToParts.length > 1) {
    const queryParams = new URLSearchParams(redirectToParts[1]);
    brand = queryParams.get('brand');
  }

  if (brand) {
    const eventData = {
      brand: brand,
      category: categorySegment,
      gender: gender,
      ...utmParams,
    };
    amplitude.track('BrandView', eventData);
  }
};
