import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { type SearchMarketingCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'

const SearchMarketingCard = ({ blok }: SearchMarketingCardStoryblok) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="relative max-h-[226px] max-w-[168px] overflow-hidden rounded-[24px] bg-cover bg-center bg-no-repeat md:max-h-full md:max-w-full"
			style={{ backgroundImage: `url(${blok.imageUrl})` }}
		>
			<Link to={blok.linkToPage} className="z-10">
				<div className="absolute inset-x-0 top-3 flex justify-center">
					{' '}
					<PlentyBody size="xs" className={`text-${blok.textColor}`}>
						{blok.topBody}
					</PlentyBody>
				</div>
				<img
					alt="genderImage"
					src={blok.image?.filename}
					fetchpriority="high"
				/>
				<BlackBackgroundColorForImages />
				<div className="absolute bottom-2 left-2 flex flex-col">
					<PlentyBody size="sm" className={`text-${blok.textColor}`}>
						{blok.body}
					</PlentyBody>
					<PlentyHeading as="h7" className={`text-${blok.textColor}`}>
						{blok.heading}
					</PlentyHeading>
				</div>
			</Link>
		</div>
	)
}

export default SearchMarketingCard
