import parse from 'html-react-parser'

// Function to render custom emojis
const renderEmojis = (htmlString: string, styles: any) => {
	const options = {
		replace: (domNode: any) => {
			if (domNode.attribs && domNode.attribs['data-type'] === 'emoji') {
				const emojiName = domNode.attribs['data-name']
				let emojiCharacter = ''

				// Map your custom emoji names to actual emojis or image URLs
				switch (emojiName) {
					case 'rotating_light':
						emojiCharacter = '🚨' // You can use Unicode characters or image URLs
						break
					// Add more cases for other custom emojis
					default:
						emojiCharacter = '' // Default case if emoji is not recognized
				}

				// Return the emoji character or an image tag
				return <span className="text-white">{emojiCharacter}</span>
			} else if (domNode.type === 'text') {
				return <span style={styles}>{domNode.data}</span>
			}
		},
	}
	return parse(htmlString, options)
}

export default renderEmojis
