import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { type SearchInformationCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'

const SearchInformationCard = ({ blok }: SearchInformationCardStoryblok) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="relative max-h-[226px] max-w-[168px] overflow-hidden rounded-[24px] bg-cover bg-center bg-no-repeat  md:max-h-full md:max-w-full"
			style={{ backgroundImage: `url(${blok.imageUrl})` }}
		>
			<img alt="genderImage" src={blok.image?.filename} fetchpriority="high" />
			<BlackBackgroundColorForImages />

			<div className="absolute bottom-2 left-2  flex flex-col gap-[4px]">
				<PlentyHeading as="h7" className={`text-${blok.textColor}`}>
					{blok.heading}
				</PlentyHeading>
				<Link to={blok.linkBodyOne}>
					<PlentyBody
						className={`text-${blok.textColor} font-${blok.weight} underline`}
						size="sm"
					>
						{blok.bodyOne}
					</PlentyBody>
				</Link>
				<Link to={blok.linkPlentyBodyTwo}>
					<PlentyBody
						className={`text-${blok.textColor} font-${blok.weight} underline`}
						size="sm"
					>
						{blok.bodyTwo}
					</PlentyBody>
				</Link>
				<Link to={blok.linkPlentyBodyThree}>
					<PlentyBody
						className={`text-${blok.textColor} font-${blok.weight} underline`}
						size="sm"
					>
						{blok.bodyThree}
					</PlentyBody>
				</Link>
			</div>
		</div>
	)
}

export default SearchInformationCard
