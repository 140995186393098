import { useLoaderData } from '@remix-run/react'
import SearchMarketingCard from './search-marketing-card'

type ImageDetails = {
	id: number
	alt: string
	name: string
	focus: string
	title: string
}

type Column = {
	component: string
	_uid: string
	body: string
	image: ImageDetails
	heading: string
	topBody: string
	linkToPage: string
	textColor: string
}

type Content = {
	body: any[] // Since this doesn't seem relevant for rendering, type as any or further specify if needed
	columns: Column[]
}

type Story = {
	content: Content
}

type StoriesData = {
	stories: Story[]
}

type LoaderData = {
	searchData: {
		data: StoriesData
	}
}

const SearchNoResults = () => {
	const { searchData } = useLoaderData<LoaderData>()

	// Access the first story's content if it exists, otherwise default to an empty array
	const story = searchData.data.stories[0]?.content.body ?? [] // Accessing the first story's body array

	return (
		<div className="space-y-3 bg-white">
			{story.map((bodyItem: any) => {
				return (
					<div
						key={bodyItem._uid}
						className="grid grid-cols-2 gap-4 overflow-x-auto scrollbar-hide md:grid-cols-4"
					>
						{bodyItem.columns.map((column: Column, index: number) => (
							<div
								key={column._uid}
								className="min-w-[40%] shrink-0 md:min-w-[25%]"
							>
								<SearchMarketingCard
									key={column._uid}
									blok={column}
									_uid={''}
									component={'searchMarketingCard'}
								/>
							</div>
						))}
					</div>
				)
			})}
		</div>
	)
}

export default SearchNoResults
